import React from 'react'

import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'
import WorkExperience from '../components/WorkExperience'

const pageTitle = 'My Work'

function WorkPage(props) {
  return (
    <>
      <FunkyTitle title={pageTitle} />
      <WorkExperience />
    </>
  )
}

WorkPage.Layout = Layout
WorkPage.pageTitle = pageTitle

export default WorkPage
