import React from 'react'
// import React, { useEffect, useRef } from 'react'
// import { TweenMax, Power3 } from 'gsap'

import RegContent from '../css/RegContent'
import List from '../css/List'
import Heading from '../css/Heading'
import Highlights from '../css/Highlights'
import Section from '../css/Section'
import ListItem from '../css/ListItem'

// const someCOntent =
//   'I worked on building chatbots with integrations for brands and on realtime systems for interactive twitter campaigns.'

export default function WorkExperience() {
  // let listItem = useRef(null)
  // useEffect(() => {
  //   TweenMax.from(listItem, { y: 200, ease: Power3.easeIn, duration: 0.3, delay: 0.3 })
  //   TweenMax.to(listItem, { opacity: 1, ease: Power3.easeOut, duration: 0, delay: 0.6 })
  // }, [])

  return (
    <Section>
      <RegContent>
        <ul>
          <ListItem>
            <Heading>Crescent Enterprises</Heading>
            <p>Software Engineer</p>
            <Highlights>feb 2019 - present</Highlights>
            <List>
              <li>Reduced hosting fees by 65% by migrating to AWS and rearchitecting projects</li>
              <li>Optimized production project to improve pageload performance by 80% across the frontend and backend</li>
              <li>Introduced tooling to increase developer velocity</li>
              <li>Built core codebase to be used across all startups</li>
              <li>Implemented advanced monitoring and logging for compliance</li>
              <li>Integrated with several 3rd party APIs for payment gateways(Stripe, Checkout, PayPal), content, FX rates, etc.</li>
              <li>Independently coordinated with project owners to get from MVP to public beta</li>
            </List>
          </ListItem>
          <ListItem>
            <Heading>Brndstr Inc</Heading>
            <p>Software Engineer</p>
            <Highlights>jul 2017 - jan 2019</Highlights>
            <List>
              <li>
                Implemented advanced architecture &amp; features for realtime twitter platform (Avg
                1.5m requests a month)
              </li>
              <li>
                Built and deployed business critical applications on AWS
                stack(EC2, ELB, Route 53, S3, CloudFront, SQS, Lambda, IAM) and
                on premises(docker, nginx, pm2)
              </li>
              <li>
                Developed API’s for front-end and client consumption (Node.Js)
              </li>
              <li>Handled end to end execution of projects, from requirements stage to deployment</li>
              <li>Built features for Ember.js app</li>
              <li>Diagnosed and optimized Node.Js &amp; MongoDB performance</li>
              <li>TDD for multi-platform chatbot &amp; twitter platform</li>
            </List>
          </ListItem>
          <ListItem>
            <Heading>Brndstr Inc</Heading>
            <p>Software Engineer - Intern</p>
            <Highlights>apr 2017 - jun 2017</Highlights>
            <List>
              <li>
                Conducted research on tech stack and proposed architecture for
                major project and presented results to senior team and client
                technical team
              </li>
              <li>
                Built Node.Js app &amp; front-end for updating IBM Watson using CSV
                file upload, moderating Watson’s responses &amp; assigning intent to
                failed phrases
              </li>
              <li>
                Implemented Auth0 integration into existing emberjs client
                platform
              </li>
              <li>Build image recognition prototype for Big Three car manufacturer</li>
            </List>
          </ListItem>
        </ul>
      </RegContent>
    </Section>
  )
}
